import * as bairdResearchService from "#app/_api/baird-research-service";
import * as financialPlansService from "#app/_api/financial-plans-service";
import { type FinancialPlanStatus } from "#app/_api/financial-plans-service";
import * as performanceService from "#app/_api/performance-reports-service";
import * as trustService from '#app/_api/trust-service';
import { EligibilityResponse } from "#app/_api/trust-service";
import * as userProfileService from "#app/_api/user-profile-service";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export interface RequiredData {
  hasBairdResearch: boolean;
  canUseFinancialPlanning: boolean;
  hasVisiblePlans: boolean;
  hasApl: boolean;
  canUseTrust: boolean;
}

interface RequiredDataQueryResult {
  isPendingRequiredData: boolean;
  requiredData: RequiredData;
}

const FIN_PLAN = 1;
const APL = 2;
const BAIRD_RESEARCH = 4;
const BAIRD_TRUST = 5;

function combineQueries(
  results: UseQueryResult<any>[],
): RequiredDataQueryResult {
  return {
    isPendingRequiredData: results.some((result) => result.isPending),
    requiredData: {
      hasBairdResearch: (results[BAIRD_RESEARCH]?.data as boolean) ?? false,
      canUseFinancialPlanning:
        (results[FIN_PLAN]?.data as FinancialPlanStatus)
          ?.canUseFinancialPlanning ?? false,
      hasVisiblePlans:
        (results[FIN_PLAN]?.data as FinancialPlanStatus)?.hasVisiblePlans ??
        false,
      hasApl: (results[APL]?.data as boolean) ?? false,
      canUseTrust: (results[BAIRD_TRUST]?.data as EligibilityResponse)?.trust === "Allow",
    },
  };
}

export function useRequiredData() {
  const queryOptions = [
    useQuery(userProfileService.useAccountGroupsQueryOptions()),
    useQuery(financialPlansService.useFinancialPlansStatusQueryOptions()),
    useQuery(performanceService.useHasAplQueryOptions()),
    useQuery(userProfileService.useAccountNickNameQueryOptions()),
    useQuery(bairdResearchService.useBairdResearchAccessQueryOptions()),
    useQuery(trustService.useTrustEligibilityMenuQueryOptions()),
  ];

  return combineQueries(queryOptions);
}
